import React from 'react'
import styled from 'styled-components'
import { graphql } from 'gatsby'
import tw from 'twin.macro'
import SEO from '../components/SEO/seo'
import FadeInContent from '../components/FadeInContent'
import SliceZone from '../components/SliceZone'

export default ({ data }) => {
  if (!data) return null
  const doc = data.prismic.allHomepage_s.edges[0]
  const slices = doc.node.body

  return (
    <>
      <SEO title='Organic Vineyard' />
      <FadeInContent>
        <Grid>
          <Wrapper>
            <SliceZone slices={slices} />
          </Wrapper>
        </Grid>
      </FadeInContent>
    </>
  )
}

const Wrapper = styled.div`
    ${tw`w-full h-full cursor-pointer relative`}
    grid-column: ${props => props.isSmallerScreen ? '' : '2 / span 4;'};
`;

const Grid = styled.div`
    ${tw`block sm:grid`}
    grid-template-columns: ${props => props.isSmallerScreen ? '' : 'repeat(6, minmax(0, 1fr));'};
`;


export const query = graphql`
query HomepageQuery($lang: String) {
  prismic {
    allHomepage_s(lang: $lang) {
      edges {
        node {
          _meta {
            lang
            type
            uid
            alternateLanguages {
              lang
              type
              uid
            }
          }
          body {
            ... on PRISMIC_Homepage_BodyImage_link {
              type
              label
              primary {
                image
                text
                link {
                  _linkType
                  ... on PRISMIC_About_page {
                    page_title
                    _linkType
                    _meta {
                      lang
                      type
                      uid
                      alternateLanguages {
                        lang
                        type
                        uid
                      }
                    }
                  }
                  ... on PRISMIC_Wines_page {
                    _linkType
                    _meta {
                      lang
                      type
                      uid
                      alternateLanguages {
                        lang
                        type
                        uid
                      }
                    }
                  }
                  ... on PRISMIC_Olive_oil {
                    productname
                    _linkType
                    _meta {
                      lang
                      type
                      uid
                      alternateLanguages {
                        lang
                        type
                        uid
                      }
                    }
                  }
                }
              }
            }
          }
        }
      }
    }
  }
}
`;


/*   &-reveal {
        .widget {
            &-animation {
                .content {
                    animation-name: home_grid_image_reveal;
                    animation-duration: 1.2s;
                    animation-fill-mode: backwards;
                    animation-timing-function: ease-in-out;
                    animation-iteration-count: 1;
                }

                .caption {
                    animation-name: home_grid_type_reveal;
                    animation-duration: 0.5s;
                    animation-delay: 2.3s;
                    animation-fill-mode: backwards;
                    animation-timing-function: ease-in-out;
                    animation-iteration-count: 1;
                }
            }
        }

        @keyframes home_grid_image_reveal {
            0% {
                opacity: 0;
                transform: translate3d(0, 50px, 0);
            }

            10% {
                opacity: 0;
                transform: translate3d(0, 50px, 0);
            }

            90% {
                opacity: 1;
                transform: translate3d(0, 0, 0);
            }

            100% {
                opacity: 1;
                transform: translate3d(0, 0, 0);
            }
        }

        @keyframes home_grid_type_reveal {
            0% {
                opacity: 0;
            }

            10% {
                opacity: 0;
            }

            90% {
                opacity: 1;
            }

            100% {
                opacity: 1;
            }
        }
    }*/
